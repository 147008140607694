// @ts-nocheck
import mongoose, { Document, Schema } from 'mongoose';

// export const ALLOWED_LANGUAGES = ['en', 'he', 'ar', 'de', 'es'];

export const PUBLISHED_TYPE = {
  published: 'PUBLISHED',
  unpublished: 'UNPUBLISHED',
};

//

export const ACTIVITY_VISIBILITY = {
  public_activity: 0,
  protected_activity: 1,
  private_organization_activity: 2,
};

export const ACTIVITY_PLANNING_COLUMN_TYPE = {
  source: 1,
  destination: 2,
  bin: 3,
};

export const WORD_FROM_THE_DEFINITION_CONFIG = {
  totalSlideCount: 12,
  columnType: {
    wordDefinition: 1,
    coverImageDropper: 2,
  },
  subColumnType: {
    definition: `definition`,
    additionalInformationCue: `additionalInformationCue`,
    sentenceCompleteCue: `sentenceCompleteCue`,
    firstSoundCue: `firstSoundCue`,
    answer: `answer`,
  },
};

export const JUDGEMENT_CONFIG = {
  totalSlideCount: 4,
  totalAnswerCount: 4,
  judgementType: {
    text: 'text',
    audio: 'audio',
    image: 'image',
    imageWithText: 'imageWithText',
    video: 'video',
  },
  judgementColumType: {
    reason: 'reason',
    solutions: 'solutions',
    waysToAvoid: 'waysToAvoid',
  },
};

export const JUDGEMENT_TYPE_DROPDOWN = [
  {
    label: 'Text',
    value: JUDGEMENT_CONFIG.judgementType.text,
  },
  {
    label: 'Image + Text',
    value: JUDGEMENT_CONFIG.judgementType.imageWithText,
  },
  {
    label: 'Audio',
    value: JUDGEMENT_CONFIG.judgementType.audio,
  },
  {
    label: 'Image',
    value: JUDGEMENT_CONFIG.judgementType.image,
  },
  {
    label: 'Video',
    value: JUDGEMENT_CONFIG.judgementType.video,
  },
];

export const SPEECH_SOUND_PICTURE_SCENES_CONFIG = {
  totalSlideCount: 4,
  defaultPhrasesCount: 4,
  aspectRatioLimit: {
    minAspectRatio: 0.9,
    maxAspectRatio: 1.1,
  },
};

export const WEEKLY_SCHEDULE_ACTIVITY_CONFIG = {
  columnType: {
    source: 1,
  },
  swimlanesTypes: {
    fixedSwimlanes: 'fixedSwimlanes',
    customSwimlanes: 'customSwimlanes',
  },
  fixedSwimlaneOptions: ['Morning', 'Noon', 'Evening', 'Night'],
  daysOption: [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ],
};

export const STORY_SEQUENCE_ACTIVITY_CONFIG = {
  slideCount: {
    minSlideCount: 3,
    maxSlideCount: 8,
  },
  aspectRatioLimit: {
    square: {
      minAspectRatio: 0.9,
      maxAspectRatio: 1.1,
    },
    rectangle: {
      minAspectRatio: 1.3,
      maxAspectRatio: 1.7,
    },
  },
};

export const RECALL_DAILY_TASKS_CONFIG = {
  totalSlideCount: 2,
  defaultAnswerCount: 4,
  columnType: {
    recallDailyTasks: 1,
    coverImageDropper: 2,
  },
};

export const ALLOWED_ORGANIZATION_FOR_ACTIVITY_VISIBILITY_CHANGE = [
  '631ed7d0e8c24fce83812d3c',
  '6375eae5900b319db072a92f',
];

export const ALLOWED_LANGUAGES = {
  en: 'en',
  he: 'he',
  ar: 'ar',
  de: 'de',
  es: 'es',
  be: 'be',
  ru: 'ru',
};

export const CURRENTLY_NEEDED_POSTFIX_IN_AUDIO_LANG = [
  ALLOWED_LANGUAGES.be,
  ALLOWED_LANGUAGES.de,
];

export const USE_GOOGLE_AS_DEFAULT_TEXT_TO_SPEECH_ENGIN_LANG = [
  ALLOWED_LANGUAGES.be,
  ALLOWED_LANGUAGES.ar,
  ALLOWED_LANGUAGES.es,
];

export const ACTIVITY_ENUM = {
  orientation: ['Landscape', 'Portrait'],
  status: {
    started: 'Started',
    waitingForReview: 'WaitingForReview',
    technicalWriterPending: 'TechnicalWriterPending',
    technicalWriterApproved: 'TechnicalWriterApproved',
    ready: 'Ready',
    published: 'Published',
  },
  audience: ['All', 'Kids', 'Elderly'],
  target_audience: ['Kids', 'Teens/Adults'],
  mediaType: {
    photo: 'Photo',
    video: 'Video',
    isolatePhoto: 'Isolate Photo',
    illustration: 'Illustration',
    text: 'Text',
  },
  targetDomain: ['All', 'K12', 'Geriatric', 'Rehabilitation'],
  languageCode: Object.values(ALLOWED_LANGUAGES),
  waitingForAudio: Object.values(ALLOWED_LANGUAGES),
  publishedToLanguage: Object.values(ALLOWED_LANGUAGES),
  published: ALLOWED_LANGUAGES,
  levels: {
    A: [1, 2],
    B: [3, 4],
    C: [5, 6],
    D: [7, 8],
    E: [9, 10],
  },
  voiceSupportedEngine: { microsoft: 'ms', google: 'google' },
  textToSpeechSettings: {
    defaultActivitiesEngine: 'google',
    defaultStudioEngine: 'ms',
    supportedEngines: {
      ms: {
        engineName: 'ms',
        ssmlSettings: null,
        IPASupport: true,
        languageCode: 'en-US',
        defaultWordVoice: 'en-US-SaraNeural',
        defaultSentenceVoice: 'en-US-SaraNeural',
      },
      google: {
        EngineName: 'google',
        ssmlSettings: null,
        IPASupport: true,
        languageCode: 'en-US',
        defaultWordVoice: 'en-US-SaraNeural',
        defaultSentenceVoice: 'en-US-SaraNeural',
      },
    },
  },
  defaultGlobalSSMLSettings: {
    en: {
      wordSpeed: 0.85,
      sentenceSpeed: 0.95,
      initialBreak: 600,
      breakBetweenWords: 0,
      pitch: 0,
    },
    ar: {
      wordSpeed: 0.85,
      sentenceSpeed: 0.95,
      initialBreak: 600,
      breakBetweenWords: 0,
      pitch: 0,
    },
    he: {
      wordSpeed: 0.85,
      sentenceSpeed: 0.95,
      initialBreak: 600,
      breakBetweenWords: 0,
      pitch: 0,
    },
    de: {
      wordSpeed: 0.75,
      sentenceSpeed: 0.85,
      initialBreak: 600,
      breakBetweenWords: 0,
      pitch: 0,
    },
    es: {
      wordSpeed: 0.75,
      sentenceSpeed: 0.85,
      initialBreak: 600,
      breakBetweenWords: 0,
      pitch: 0,
    },
    be: {
      wordSpeed: 0.85,
      sentenceSpeed: 0.95,
      initialBreak: 600,
      breakBetweenWords: 0,
      pitch: 0,
    },
    ru: {
      wordSpeed: 0.85,
      sentenceSpeed: 0.95,
      initialBreak: 600,
      breakBetweenWords: 0,
      pitch: 0,
    },
  },
  languageSpeechConfig: {
    default: {
      en: {
        languageCode: 'en-US',
        voiceName: 'en-US-SaraNeural',
        sentenceVoiceName: 'en-US-SaraNeural',
      },
      ar: {
        languageCode: 'ar-JO',
        voiceName: 'ar-JO-SanaNeural',
        sentenceVoiceName: 'ar-JO-SanaNeural',
      },
      he: {
        languageCode: 'he-IL',
        voiceName: 'he-IL-HilaNeural',
        sentenceVoiceName: 'he-IL-HilaNeural',
      },
      de: {
        languageCode: 'de-DE',
        voiceName: 'de-DE-ElkeNeural',
        sentenceVoiceName: 'de-DE-ElkeNeural',
      },
      es: {
        languageCode: 'en-US',
        voiceName: 'en-US-SaraNeural',
        sentenceVoiceName: 'en-US-SaraNeural',
      },
      be: {
        languageCode: 'en-GB',
        voiceName: 'en-GB-SoniaNeural',
        sentenceVoiceName: 'en-GB-SoniaNeural',
      },
      ru: {
        languageCode: 'ru-RU',
        voiceName: 'ru-RU-DariyaNeural',
        sentenceVoiceName: 'ru-RU-DariyaNeural',
      },
    },
    en: [
      {
        language: 'Sara (Neural)',
        voiceName: 'en-US-SaraNeural',
        languageCode: 'en-US',
        default: true,
      },
      {
        language: 'Jenny (Neural)',
        voiceName: 'en-US-JennyNeural',
        languageCode: 'en-US',
      },
      {
        language: 'Jenny Multilingual (Neural)',
        voiceName: 'en-US-JennyMultilingualNeural',
        languageCode: 'en-US',
      },
      {
        language: 'Guy (Neural)',
        voiceName: 'en-US-GuyNeural',
        languageCode: 'en-US',
      },
      {
        language: 'Amber (Neural)',
        voiceName: 'en-US-AmberNeural',
        languageCode: 'en-US',
      },
      {
        language: 'Ana (Neural)',
        voiceName: 'en-US-AnaNeural',
        languageCode: 'en-US',
      },
      {
        language: 'Aria (Neural)',
        voiceName: 'en-US-AriaNeural',
        languageCode: 'en-US',
      },
      {
        language: 'Ashley (Neural)',
        voiceName: 'en-US-AshleyNeural',
        languageCode: 'en-US',
      },
      {
        language: 'Brandon (Neural)',
        voiceName: 'en-US-BrandonNeural',
        languageCode: 'en-US',
      },
      {
        language: 'Christopher (Neural)',
        voiceName: 'en-US-ChristopherNeural',
        languageCode: 'en-US',
      },
      {
        language: 'Elizabeth (Neural)',
        voiceName: 'en-US-ElizabethNeural',
        languageCode: 'en-US',
      },
      {
        language: 'Eric (Neural)',
        voiceName: 'en-US-EricNeural',
        languageCode: 'en-US',
      },
      {
        language: 'Jacob (Neural)',
        voiceName: 'en-US-JacobNeural',
        languageCode: 'en-US',
      },
      {
        language: 'Michelle (Neural)',
        voiceName: 'en-US-MichelleNeural',
        languageCode: 'en-US',
      },
      {
        language: 'Monica (Neural)',
        voiceName: 'en-US-MonicaNeural',
        languageCode: 'en-US',
      },
    ],
    he: [
      {
        language: 'Avri (Neural)',
        voiceName: 'he-IL-AvriNeural',
        languageCode: 'he-IL',
        default: true,
      },
      {
        language: 'Hila (Neural)',
        voiceName: 'he-IL-HilaNeural',
        languageCode: 'he-IL',
      },
    ],

    ar: [
      {
        language: 'Sana (Neural)',
        voiceName: 'ar-JO-SanaNeural',
        languageCode: 'ar-JO',
        default: true,
      },
      {
        language: 'Taim (Neural)',
        voiceName: 'ar-JO-TaimNeural',
        languageCode: 'ar-JO',
      },
    ],
    de: [
      {
        language: 'Elke (Neural)',
        voiceName: 'de-DE-ElkeNeural',
        languageCode: 'de-DE',
        default: true,
      },
      {
        language: 'Katja (Neural)',
        voiceName: 'de-DE-KatjaNeural',
        languageCode: 'de-DE',
      },
      {
        language: 'Conrad (Neural)',
        voiceName: 'de-DE-ConradNeural',
        languageCode: 'de-DE',
      },
      {
        language: 'Amala (Neural)',
        voiceName: 'de-DE-AmalaNeural',
        languageCode: 'de-DE',
      },
      {
        language: 'Bernd (Neural)',
        voiceName: 'de-DE-BerndNeural',
        languageCode: 'de-DE',
      },
      {
        language: 'Christoph (Neural)',
        voiceName: 'de-DE-ChristophNeural',
        languageCode: 'de-DE',
      },
      {
        language: 'Gisela (Neural)',
        voiceName: 'de-DE-GiselaNeural',
        languageCode: 'de-DE',
      },
      {
        language: 'Kasper (Neural)',
        voiceName: 'de-DE-KasperNeural',
        languageCode: 'de-DE',
      },
      {
        language: 'Killian (Neural)',
        voiceName: 'de-DE-KillianNeural',
        languageCode: 'de-DE',
      },
      {
        language: 'Klarissa (Neural)',
        voiceName: 'de-DE-KlarissaNeural',
        languageCode: 'de-DE',
      },
      {
        language: 'Klaus (Neural)',
        voiceName: 'de-DE-KlausNeural',
        languageCode: 'de-DE',
      },
      {
        language: 'Louisa (Neural)',
        voiceName: 'de-DE-LouisaNeural',
        languageCode: 'de-DE',
      },
      {
        language: 'Maja (Neural)',
        voiceName: 'de-DE-MajaNeural',
        languageCode: 'de-DE',
      },
      {
        language: 'Ralf (Neural)',
        voiceName: 'de-DE-RalfNeural',
        languageCode: 'de-DE',
      },
      {
        language: 'Tanja (Neural)',
        voiceName: 'de-DE-TanjaNeural',
        languageCode: 'de-DE',
      },
    ],
    es: [
      {
        language: 'Sara (Neural)',
        voiceName: 'en-US-SaraNeural',
        languageCode: 'en-US',
        default: true,
      },
      {
        language: 'Jenny (Neural)',
        voiceName: 'en-US-JennyNeural',
        languageCode: 'en-US',
      },
    ],
    be: [
      {
        language: 'en-GB-SoniaNeural',
        voiceName: 'en-GB-SoniaNeural',
        languageCode: 'en-GB',
        default: true,
      },
      {
        language: 'en-GB-RyanNeural',
        voiceName: 'en-GB-RyanNeural',
        languageCode: 'en-GB',
        default: false,
      },
      {
        language: 'en-GB-LibbyNeural',
        voiceName: 'en-GB-LibbyNeural',
        languageCode: 'en-GB',
        default: false,
      },
      {
        language: 'en-GB-AbbiNeural',
        voiceName: 'en-GB-AbbiNeural',
        languageCode: 'en-GB',
        default: false,
      },
      {
        language: 'en-GB-AlfieNeural',
        voiceName: 'en-GB-AlfieNeural',
        languageCode: 'en-GB',
        default: false,
      },
      {
        language: 'en-GB-BellaNeural',
        voiceName: 'en-GB-BellaNeural',
        languageCode: 'en-GB',
        default: false,
      },
      {
        language: 'en-GB-ElliotNeural',
        voiceName: 'en-GB-ElliotNeural',
        languageCode: 'en-GB',
        default: false,
      },
      {
        language: 'en-GB-EthanNeural',
        voiceName: 'en-GB-EthanNeural',
        languageCode: 'en-GB',
        default: false,
      },
      {
        language: 'en-GB-HollieNeural',
        voiceName: 'en-GB-HollieNeural',
        languageCode: 'en-GB',
        default: false,
      },
      {
        language: 'en-GB-MaisieNeural',
        voiceName: 'en-GB-MaisieNeural',
        languageCode: 'en-GB',
        default: false,
      },
      {
        language: 'en-GB-NoahNeural',
        voiceName: 'en-GB-NoahNeural',
        languageCode: 'en-GB',
        default: false,
      },
      {
        language: 'en-GB-OliverNeural',
        voiceName: 'en-GB-OliverNeural',
        languageCode: 'en-GB',
        default: false,
      },
      {
        language: 'en-GB-OliviaNeural',
        voiceName: 'en-GB-OliviaNeural',
        languageCode: 'en-GB',
        default: false,
      },
      {
        language: 'en-GB-ThomasNeural',
        voiceName: 'en-GB-ThomasNeural',
        languageCode: 'en-GB',
        default: false,
      },
    ],
    ru: [
      {
        language: 'Sara (Neural)',
        voiceName: 'ru-RU-DariyaNeural',
        languageCode: 'ru-RU',
        default: true,
      },
      {
        language: 'Svetlana (Neural)',
        voiceName: 'ru-RU-SvetlanaNeural',
        languageCode: 'ru-RU',
      },
      {
        language: 'Dmitry (Neural)',
        voiceName: 'ru-RU-DmitryNeural',
        languageCode: 'ru-RU',
      },
    ],
  },

  languageSpeechConfigForGoogle: {
    default: {
      en: {
        languageCode: 'en-US',
        voiceName: 'en-US-Wavenet-F',
        sentenceVoiceName: 'en-US-Wavenet-F',
      },
      ar: {
        languageCode: 'ar-XA',
        voiceName: 'ar-XA-Standard-D',
        sentenceVoiceName: 'ar-XA-Standard-D',
      },
      he: {
        languageCode: 'he-IL',
        voiceName: 'he-IL-Wavenet-C',
        sentenceVoiceName: 'he-IL-Wavenet-C',
      },
      de: {
        languageCode: 'de-DE',
        voiceName: 'de-DE-Neural2-A',
        sentenceVoiceName: 'de-DE-Neural2-A',
      },
      be: {
        voiceName: 'en-GB-Neural2-A',
        sentenceVoiceName: 'en-GB-Neural2-A',
        languageCode: 'en-GB',
      },
      es: {
        voiceName: 'es-US-Wavenet-A',
        sentenceVoiceName: 'es-US-Wavenet-A',
        languageCode: 'es-ES',
      },
      ru: {
        voiceName: 'ru-RU-Standard-A',
        sentenceVoiceName: 'ru-RU-Standard-A',
        languageCode: 'ru-RU',
      },
    },
    en: [
      {
        language: 'English (US)',
        voiceName: 'en-US-Neural2-A',
        languageCode: 'en-US',
        default: false,
      },
      {
        language: 'English (US)',
        voiceName: 'en-US-Neural2-C',
        languageCode: 'en-US',
        default: false,
      },
      {
        language: 'English (US)',
        voiceName: 'en-US-Neural2-D',
        languageCode: 'en-US',
        default: false,
      },
      {
        language: 'English (US)',
        voiceName: 'en-US-Neural2-E',
        languageCode: 'en-US',
        default: false,
      },
      {
        language: 'English (US)',
        voiceName: 'en-US-Neural2-F',
        languageCode: 'en-US',
        default: false,
      },
      {
        language: 'English (US)',
        voiceName: 'en-US-Neural2-G',
        languageCode: 'en-US',
        default: false,
      },
      {
        language: 'English (US)',
        voiceName: 'en-US-Neural2-H',
        languageCode: 'en-US',
        default: false,
      },
      {
        language: 'English (US)',
        voiceName: 'en-US-Neural2-I',
        languageCode: 'en-US',
        default: false,
      },
      {
        language: 'English (US)',
        voiceName: 'en-US-Neural2-J',
        languageCode: 'en-US',
        default: false,
      },
      {
        language: 'English (US)',
        voiceName: 'en-US-News-K',
        languageCode: 'en-US',
        default: false,
      },
      {
        language: 'English (US)',
        voiceName: 'en-US-News-L',
        languageCode: 'en-US',
        default: false,
      },
      {
        language: 'English (US)',
        voiceName: 'en-US-News-N',
        languageCode: 'en-US',
        default: false,
      },
      {
        language: 'English (US)',
        voiceName: 'en-US-Polyglot-1',
        languageCode: 'en-US',
        default: false,
      },
      {
        language: 'English (US)',
        voiceName: 'en-US-Standard-A',
        languageCode: 'en-US',
        default: false,
      },
      {
        language: 'English (US)',
        voiceName: 'en-US-Standard-B',
        languageCode: 'en-US',
        default: false,
      },
      {
        language: 'English (US)',
        voiceName: 'en-US-Standard-C',
        languageCode: 'en-US',
        default: false,
      },
      {
        language: 'English (US)',
        voiceName: 'en-US-Standard-D',
        languageCode: 'en-US',
        default: false,
      },
      {
        language: 'English (US)',
        voiceName: 'en-US-Standard-E',
        languageCode: 'en-US',
        default: false,
      },
      {
        language: 'English (US)',
        voiceName: 'en-US-Standard-F',
        languageCode: 'en-US',
        default: false,
      },
      {
        language: 'English (US)',
        voiceName: 'en-US-Standard-G',
        languageCode: 'en-US',
        default: false,
      },
      {
        language: 'English (US)',
        voiceName: 'en-US-Standard-H',
        languageCode: 'en-US',
        default: false,
      },
      {
        language: 'English (US)',
        voiceName: 'en-US-Standard-I',
        languageCode: 'en-US',
        default: false,
      },
      {
        language: 'English (US)',
        voiceName: 'en-US-Standard-J',
        languageCode: 'en-US',
        default: false,
      },
      {
        language: 'English (US)',
        voiceName: 'en-US-Studio-O',
        languageCode: 'en-US',
        default: false,
      },
      {
        language: 'English (US)',
        voiceName: 'en-US-Studio-Q',
        languageCode: 'en-US',
        default: false,
      },
      {
        language: 'English (US)',
        voiceName: 'en-US-Wavenet-A',
        languageCode: 'en-US',
        default: false,
      },
      {
        language: 'English (US)',
        voiceName: 'en-US-Wavenet-B',
        languageCode: 'en-US',
        default: false,
      },
      {
        language: 'English (US)',
        voiceName: 'en-US-Wavenet-C',
        languageCode: 'en-US',
        default: false,
      },
      {
        language: 'English (US)',
        voiceName: 'en-US-Wavenet-D',
        languageCode: 'en-US',
        default: false,
      },
      {
        language: 'English (US)',
        voiceName: 'en-US-Wavenet-E',
        languageCode: 'en-US',
        default: false,
      },
      {
        language: 'English (US)',
        voiceName: 'en-US-Wavenet-F',
        languageCode: 'en-US',
        default: true,
      },
      {
        language: 'English (US)',
        voiceName: 'en-US-Wavenet-G',
        languageCode: 'en-US',
        default: false,
      },
      {
        language: 'English (US)',
        voiceName: 'en-US-Wavenet-H',
        languageCode: 'en-US',
        default: false,
      },
      {
        language: 'English (US)',
        voiceName: 'en-US-Wavenet-I',
        languageCode: 'en-US',
        default: false,
      },
      {
        language: 'English (US)',
        voiceName: 'en-US-Wavenet-J',
        languageCode: 'en-US',
        default: false,
      },
    ],
    he: [
      {
        language: 'Hebrew (Israel)',
        voiceName: 'he-IL-Wavenet-C',
        languageCode: 'he-IL',
        default: true,
      },
      {
        language: 'Hebrew (Israel)',
        voiceName: 'he-IL-Standard-A',
        languageCode: 'he-IL',
        default: false,
      },
      {
        language: 'Hebrew (Israel)',
        voiceName: 'he-IL-Standard-B',
        languageCode: 'he-IL',
        default: false,
      },
      {
        language: 'Hebrew (Israel)',
        voiceName: 'he-IL-Standard-C',
        languageCode: 'he-IL',
        default: false,
      },
      {
        language: 'Hebrew (Israel)',
        voiceName: 'he-IL-Standard-D',
        languageCode: 'he-IL',
        default: false,
      },
      {
        language: 'Hebrew (Israel)',
        voiceName: 'he-IL-Wavenet-A',
        languageCode: 'he-IL',
        default: false,
      },
      {
        language: 'Hebrew (Israel)',
        voiceName: 'he-IL-Wavenet-B',
        languageCode: 'he-IL',
        default: false,
      },
      {
        language: 'Hebrew (Israel)',
        voiceName: 'he-IL-Wavenet-D',
        languageCode: 'he-IL',
        default: false,
      },
    ],
    ar: [
      {
        language: 'Arabic',
        voiceName: 'ar-XA-Standard-D',
        languageCode: 'ar-XA',
        default: true,
      },
      {
        language: 'Arabic',
        voiceName: 'ar-XA-Standard-A',
        languageCode: 'ar-XA',
        default: false,
      },
      {
        language: 'Arabic',
        voiceName: 'ar-XA-Standard-B',
        languageCode: 'ar-XA',
        default: false,
      },
      {
        language: 'Arabic',
        voiceName: 'ar-XA-Standard-C',
        languageCode: 'ar-XA',
        default: false,
      },
      {
        language: 'Arabic',
        voiceName: 'ar-XA-Wavenet-A',
        languageCode: 'ar-XA',
        default: false,
      },
      {
        language: 'Arabic',
        voiceName: 'ar-XA-Wavenet-B',
        languageCode: 'ar-XA',
        default: false,
      },
      {
        language: 'Arabic',
        voiceName: 'ar-XA-Wavenet-C',
        languageCode: 'ar-XA',
        default: false,
      },
      {
        language: 'Arabic',
        voiceName: 'ar-XA-Wavenet-D',
        languageCode: 'ar-XA',
        default: false,
      },
    ],
    de: [
      {
        language: 'German (Germany)',
        voiceName: 'de-DE-Neural2-A',
        languageCode: 'de-DE',
        default: true,
      },
      {
        language: 'German (Germany)',
        voiceName: 'de-DE-Neural2-B',
        languageCode: 'de-DE',
        default: false,
      },
      {
        language: 'German (Germany)',
        voiceName: 'de-DE-Neural2-C',
        languageCode: 'de-DE',
        default: false,
      },
      {
        language: 'German (Germany)',
        voiceName: 'de-DE-Neural2-D',
        languageCode: 'de-DE',
        default: false,
      },
      {
        language: 'German (Germany)',
        voiceName: 'de-DE-Neural2-F',
        languageCode: 'de-DE',
        default: false,
      },
      {
        language: 'German (Germany)',
        voiceName: 'de-DE-Polyglot-1',
        languageCode: 'de-DE',
        default: false,
      },
      {
        language: 'German (Germany)',
        voiceName: 'de-DE-Standard-A',
        languageCode: 'de-DE',
        default: false,
      },
      {
        language: 'German (Germany)',
        voiceName: 'de-DE-Standard-B',
        languageCode: 'de-DE',
        default: false,
      },
      {
        language: 'German (Germany)',
        voiceName: 'de-DE-Standard-C',
        languageCode: 'de-DE',
        default: false,
      },
      {
        language: 'German (Germany)',
        voiceName: 'de-DE-Standard-D',
        languageCode: 'de-DE',
        default: false,
      },
      {
        language: 'German (Germany)',
        voiceName: 'de-DE-Standard-E',
        languageCode: 'de-DE',
        default: false,
      },
      {
        language: 'German (Germany)',
        voiceName: 'de-DE-Standard-F',
        languageCode: 'de-DE',
        default: false,
      },
      {
        language: 'German (Germany)',
        voiceName: 'de-DE-Studio-B',
        languageCode: 'de-DE',
        default: false,
      },
      {
        language: 'German (Germany)',
        voiceName: 'de-DE-Studio-C',
        languageCode: 'de-DE',
        default: false,
      },
      {
        language: 'German (Germany)',
        voiceName: 'de-DE-Wavenet-A',
        languageCode: 'de-DE',
        default: false,
      },
      {
        language: 'German (Germany)',
        voiceName: 'de-DE-Wavenet-B',
        languageCode: 'de-DE',
        default: false,
      },
      {
        language: 'German (Germany)',
        voiceName: 'de-DE-Wavenet-C',
        languageCode: 'de-DE',
        default: false,
      },
      {
        language: 'German (Germany)',
        voiceName: 'de-DE-Wavenet-D',
        languageCode: 'de-DE',
        default: false,
      },
      {
        language: 'German (Germany)',
        voiceName: 'de-DE-Wavenet-E',
        languageCode: 'de-DE',
        default: false,
      },
      {
        language: 'German (Germany)',
        voiceName: 'de-DE-Wavenet-F',
        languageCode: 'de-DE',
        default: false,
      },
    ],
    es: [
      {
        language: 'Spanish (Spain)',
        voiceName: 'es-US-Wavenet-A',
        languageCode: 'es-US',
        default: true,
      },
      {
        language: 'Spanish (Spain)',
        voiceName: 'es-ES-Neural2-A',
        languageCode: 'es-ES',
        default: false,
      },
      {
        language: 'Spanish (Spain)',
        voiceName: 'es-ES-Neural2-B',
        languageCode: 'es-ES',
        default: false,
      },
      {
        language: 'Spanish (Spain)',
        voiceName: 'es-ES-Neural2-C',
        languageCode: 'es-ES',
        default: false,
      },
      {
        language: 'Spanish (Spain)',
        voiceName: 'es-ES-Neural2-D',
        languageCode: 'es-ES',
        default: false,
      },
      {
        language: 'Spanish (Spain)',
        voiceName: 'es-ES-Neural2-E',
        languageCode: 'es-ES',
        default: false,
      },
      {
        language: 'Spanish (Spain)',
        voiceName: 'es-ES-Neural2-F',
        languageCode: 'es-ES',
        default: false,
      },
      {
        language: 'Spanish (Spain)',
        voiceName: 'es-ES-Polyglot-1',
        languageCode: 'es-ES',
        default: false,
      },
      {
        language: 'Spanish (Spain)',
        voiceName: 'es-ES-Standard-A',
        languageCode: 'es-ES',
        default: false,
      },
      {
        language: 'Spanish (Spain)',
        voiceName: 'es-ES-Standard-B',
        languageCode: 'es-ES',
        default: false,
      },
      {
        language: 'Spanish (Spain)',
        voiceName: 'es-ES-Standard-C',
        languageCode: 'es-ES',
        default: false,
      },
      {
        language: 'Spanish (Spain)',
        voiceName: 'es-ES-Standard-D',
        languageCode: 'es-ES',
        default: false,
      },
      {
        language: 'Spanish (Spain)',
        voiceName: 'es-ES-Wavenet-B',
        languageCode: 'es-ES',
        default: false,
      },
      {
        language: 'Spanish (Spain)',
        voiceName: 'es-ES-Wavenet-C',
        languageCode: 'es-ES',
        default: false,
      },
      {
        language: 'Spanish (Spain)',
        voiceName: 'es-ES-Wavenet-D',
        languageCode: 'es-ES',
        default: false,
      },
      {
        language: 'Spanish (US)',
        voiceName: 'es-US-Neural2-A',
        languageCode: 'es-US',
        default: false,
      },
      {
        language: 'Spanish (US)',
        voiceName: 'es-US-Neural2-B',
        languageCode: 'es-US',
        default: false,
      },
      {
        language: 'Spanish (US)',
        voiceName: 'es-US-Neural2-C',
        languageCode: 'es-US',
        default: false,
      },
      {
        language: 'Spanish (US)',
        voiceName: 'es-US-News-D',
        languageCode: 'es-US',
        default: false,
      },
      {
        language: 'Spanish (US)',
        voiceName: 'es-US-News-E',
        languageCode: 'es-US',
        default: false,
      },
      {
        language: 'Spanish (US)',
        voiceName: 'es-US-News-F',
        languageCode: 'es-US',
        default: false,
      },
      {
        language: 'Spanish (US)',
        voiceName: 'es-US-News-G',
        languageCode: 'es-US',
        default: false,
      },
      {
        language: 'Spanish (US)',
        voiceName: 'es-US-Polyglot-1',
        languageCode: 'es-US',
        default: false,
      },
      {
        language: 'Spanish (US)',
        voiceName: 'es-US-Standard-A',
        languageCode: 'es-US',
        default: false,
      },
      {
        language: 'Spanish (US)',
        voiceName: 'es-US-Standard-B',
        languageCode: 'es-US',
        default: false,
      },
      {
        language: 'Spanish (US)',
        voiceName: 'es-US-Standard-C',
        languageCode: 'es-US',
        default: false,
      },
      {
        language: 'Spanish (US)',
        voiceName: 'es-US-Studio-B',
        languageCode: 'es-US',
        default: false,
      },
      {
        language: 'Spanish (US)',
        voiceName: 'es-US-Wavenet-B',
        languageCode: 'es-US',
        default: false,
      },
      {
        language: 'Spanish (US)',
        voiceName: 'es-US-Wavenet-C',
        languageCode: 'es-US',
        default: false,
      },
    ],
    ru: [
      {
        language: 'Russian (Russia)',
        voiceName: 'ru-RU-Standard-A',
        languageCode: 'ru-RU',
        default: true,
      },
      {
        language: 'Russian (Russia)',
        voiceName: 'ru-RU-Standard-B',
        languageCode: 'ru-RU',
        default: false,
      },
      {
        language: 'Russian (Russia)',
        voiceName: 'ru-RU-Standard-C',
        languageCode: 'ru-RU',
        default: false,
      },
      {
        language: 'Russian (Russia)',
        voiceName: 'ru-RU-Standard-D',
        languageCode: 'ru-RU',
        default: false,
      },
      {
        language: 'Russian (Russia)',
        voiceName: 'ru-RU-Standard-E',
        languageCode: 'ru-RU',
        default: false,
      },
      {
        language: 'Russian (Russia)',
        voiceName: 'ru-RU-Wavenet-A',
        languageCode: 'ru-RU',
        default: false,
      },
      {
        language: 'Russian (Russia)',
        voiceName: 'ru-RU-Wavenet-B',
        languageCode: 'ru-RU',
        default: false,
      },
      {
        language: 'Russian (Russia)',
        voiceName: 'ru-RU-Wavenet-C',
        languageCode: 'ru-RU',
        default: false,
      },
      {
        language: 'Russian (Russia)',
        voiceName: 'ru-RU-Wavenet-D',
        languageCode: 'ru-RU',
        default: false,
      },
      {
        language: 'Russian (Russia)',
        voiceName: 'ru-RU-Wavenet-E',
        languageCode: 'ru-RU',
        default: false,
      },
    ],
    be: [
      {
        language: 'English (Great Britain)',
        voiceName: 'en-GB-Neural2-A',
        languageCode: 'en-GB',
        default: true,
      },
      {
        language: 'English (Great Britain)',
        voiceName: 'en-GB-Neural2-B',
        languageCode: 'en-GB',
        default: false,
      },
      {
        language: 'English (Great Britain)',
        voiceName: 'en-GB-Neural2-C',
        languageCode: 'en-GB',
        default: false,
      },
      {
        language: 'English (Great Britain)',
        voiceName: 'en-GB-Neural2-D',
        languageCode: 'en-GB',
        default: false,
      },
      {
        language: 'English (Great Britain)',
        voiceName: 'en-GB-Neural2-F',
        languageCode: 'en-GB',
        default: false,
      },
      {
        language: 'English (Great Britain)',
        voiceName: 'en-GB-Studio-B',
        languageCode: 'en-GB',
        default: false,
      },
      {
        language: 'English (Great Britain)',
        voiceName: 'en-GB-Studio-C',
        languageCode: 'en-GB',
        default: false,
      },
      {
        language: 'English (Great Britain)',
        voiceName: 'en-GB-Wavenet-A',
        languageCode: 'en-GB',
        default: false,
      },
      {
        language: 'English (Great Britain)',
        voiceName: 'en-GB-Wavenet-B',
        languageCode: 'en-GB',
        default: false,
      },
      {
        language: 'English (Great Britain)',
        voiceName: 'en-GB-Wavenet-C',
        languageCode: 'en-GB',
        default: false,
      },
      {
        language: 'English (Great Britain)',
        voiceName: 'en-GB-Wavenet-D',
        languageCode: 'en-GB',
        default: false,
      },
      {
        language: 'English (Great Britain)',
        voiceName: 'en-GB-Wavenet-F',
        languageCode: 'en-GB',
        default: false,
      },
      {
        language: 'English (Great Britain)',
        voiceName: 'en-GB-News-G',
        languageCode: 'en-GB',
        default: false,
      },
      {
        language: 'English (Great Britain)',
        voiceName: 'en-GB-News-H',
        languageCode: 'en-GB',
        default: false,
      },
      {
        language: 'English (Great Britain)',
        voiceName: 'en-GB-News-I',
        languageCode: 'en-GB',
        default: false,
      },
      {
        language: 'English (Great Britain)',
        voiceName: 'en-GB-News-J',
        languageCode: 'en-GB',
        default: false,
      },
      {
        language: 'English (Great Britain)',
        voiceName: 'en-GB-News-K',
        languageCode: 'en-GB',
        default: false,
      },
      {
        language: 'English (Great Britain)',
        voiceName: 'en-GB-News-L',
        languageCode: 'en-GB',
        default: false,
      },
      {
        language: 'English (Great Britain)',
        voiceName: 'en-GB-News-M',
        languageCode: 'en-GB',
        default: false,
      },
      {
        language: 'English (Great Britain)',
        voiceName: 'en-GB-Standard-A',
        languageCode: 'en-GB',
        default: false,
      },
      {
        language: 'English (Great Britain)',
        voiceName: 'en-GB-Standard-B',
        languageCode: 'en-GB',
        default: false,
      },
      {
        language: 'English (Great Britain)',
        voiceName: 'en-GB-Standard-C',
        languageCode: 'en-GB',
        default: false,
      },
      {
        language: 'English (Great Britain)',
        voiceName: 'en-GB-Standard-D',
        languageCode: 'en-GB',
        default: false,
      },
      {
        language: 'English (Great Britain)',
        voiceName: 'en-GB-Standard-F',
        languageCode: 'en-GB',
        default: false,
      },
    ],
  },
  errorType: {
    category: 'Please select category',
    subCategory: 'Please select Subcategory',
    numberOfSlides: 'Invalid number of Slides',
    slideCount: `Number of slides must be between`,
  },
};

export const AUDIO_MODE = {
  auto: 'auto',
  custom: 'custom',
  noAudio: 'none',
};

export enum IMAGE_SHAPE_TYPE_ENUM {
  square = 'square',
  rectangle = 'rectangle',
}
export interface Activity {
  name: string;
}

export interface MongooseActivity extends Activity, Document {}

const ActivitySchema = new Schema(
  {
    name: { type: Object },
    type: { type: Schema.Types.ObjectId, ref: 'Activity-Type' },
    // description: { type: Object },
    notes: { type: String },
    level: { type: Number, min: 1, max: 10 },
    orientation: { type: String, enum: ACTIVITY_ENUM.orientation },
    mediaType: { type: String, enum: Object.values(ACTIVITY_ENUM.mediaType) },
    category: { type: Schema.Types.ObjectId, ref: 'Category' },
    subCategory: { type: Schema.Types.ObjectId, ref: 'SubCategory' },
    status: { type: String, enum: Object.values(ACTIVITY_ENUM.status) },
    sortPriority: { type: Number, default: 1 },
    groupSortPriority: { type: Number, default: 1 },
    free: { type: Boolean, default: false },
    audience: { type: String, enum: ACTIVITY_ENUM.audience },
    targetAudience: { type: [String], enum: ACTIVITY_ENUM.target_audience },
    // free: { type: Boolean, default: false },
    attachedRoles: [{ type: Schema.Types.ObjectId, ref: 'Role' }],
    printable: { type: Boolean, default: false },
    editorial: { type: Boolean, default: false },
    submittedAt: { type: Date, default: Date.now },
    tags: { type: [String], default: [] },
    targetDomain: { type: [String], enum: ACTIVITY_ENUM.targetDomain },
    languageCode: { type: [String], enum: ACTIVITY_ENUM.languageCode },
    waitingForAudio: { type: [String], enum: ACTIVITY_ENUM.waitingForAudio },
    publishedToLanguage: {
      type: [String],
      enum: ACTIVITY_ENUM.publishedToLanguage,
      default: [],
    },
    // published: { type: [String], enum: ACTIVITY_ENUM.languageCode },
    published: [{ type: Schema.Types.ObjectId, ref: 'Website', default: [] }],
    updatedBy: { type: Schema.Types.ObjectId, ref: 'User' },
    createdBy: { type: Schema.Types.ObjectId, ref: 'User' },
    ownedBy: { type: Schema.Types.ObjectId, ref: 'Organization' },
    metadata: {
      slideCategories: [Object],
      isAlternateTextOrAudio: { type: Boolean, default: false },
      coverFilename: { type: String, default: '' },
      activityIntroduction: { type: Object, default: null },
      generalActivityConfiguration: {
        isShowUnexpected: { type: Boolean, default: false },
        unexpectedText: { type: Object, default: {} },
        weeklyScheduleConfigurations: {
          removeSwimlanes: { type: Boolean, default: false },
          activityDays: { type: Array, default: [] },
          activitySwimlanes: { type: Array, default: [] },
          swimlaneType: {
            type: String,
            default:
              WEEKLY_SCHEDULE_ACTIVITY_CONFIG.swimlanesTypes.fixedSwimlanes,
          },
        },
        storySequenceConfigurations: {
          imageShapeType: {
            type: String,
            enum: Object.values(IMAGE_SHAPE_TYPE_ENUM),
          },
        },
        judgementConfigurations: {
          judgementType: {
            type: String,
            default: JUDGEMENT_CONFIG.judgementType.text,
          },
        },
      },
      slides: [
        {
          media: [{ name: { type: String }, video: { type: Boolean } }],
          phrases: [Object],
          phrasesCue: { type: Array },
          alternatePhrases: [Object],
          phraseVoice: [Object],
          alternatePhraseVoice: [Object],
          phrasesPitch: [Object],
          alternatePhasePitch: [Object],
          category: { type: Object },
          size: { type: String },
          level: { type: Number },
          activityPlaningItems: [
            {
              isBonusItem: Boolean,
              phrases: Object,
              destinationColName: { type: [String] },
              destinationColIndex: { type: [Number] },
              media: Object,
              isAlsoInBin: { type: [Boolean], default: null },
            },
          ],
          weeklyScheduleItems: [
            {
              isBonusItem: { type: Boolean, default: false },
              isCardPinned: { type: Boolean, default: false },
              phrases: Object,
              cardRepeatNumber: { type: Number, default: 1 },
              description: { type: Array, default: [] },
              cardTargetList: [
                {
                  destinationColName: { type: String, default: null },
                  destinationColSwimlaneName: { type: String, default: null },
                  destinationColIndex: { type: Number, default: null },
                },
              ],
              unexpectedCardTargetList: [
                {
                  destinationColName: { type: String, default: null },
                  destinationColSwimlaneName: { type: String, default: null },
                  destinationColIndex: { type: Number, default: null },
                },
              ],
            },
          ],
          alternateAudio: [
            {
              name: { type: String },
              language: { type: String, default: null },
            },
          ],
          audio: [
            {
              name: { type: String },
              language: { type: String, default: null },
            },
          ],
          wordDefItems: [
            [
              {
                phrases: { type: Object },
                audioDetails: { type: Object, default: null },
                subColumnType: {
                  type: String,
                  enum: Object.values(
                    WORD_FROM_THE_DEFINITION_CONFIG.subColumnType
                  ),
                },
              },
            ],
          ],

          speechSoundPictureScenesItems: {
            phrases: { type: Object },
          },

          judgementItems: {
            situationPhrase: { type: Object },
            situationDetailItems: [
              {
                columType: {
                  type: String,
                  enum: Object.values(JUDGEMENT_CONFIG.judgementColumType),
                },
                phrases: [
                  {
                    isSelected: { type: Boolean, default: false },
                    phrase: { type: Object },
                  },
                ],
              },
            ],
          },

          recallDailyTasks: {
            sender: {
              isNameChecked: { type: Boolean, default: false },
              isImageChecked: { type: Boolean, default: false },
              phrases: { type: Object },
              audioDetails: { type: Object, default: null },
              media: [
                {
                  name: { type: String },
                },
                // when storing image
                // {name: "istock-1134183690.jpg"},
              ],
            },
            note: {
              isNoteChecked: { type: Boolean, default: false },
              phrases: { type: Object },
              audioDetails: { type: Object, default: null },
            },
            textMessage: {
              isTextMessageChecked: { type: Boolean, default: false },
              phrases: { type: Object },
              audioDetails: { type: Object, default: null },
            },
            voiceMessage: {
              isVoiceMessageChecked: { type: Boolean, default: false },
              phrases: { type: Object },
              audioDetails: { type: Object, default: null },
            },
            phoneMessage: {
              isPhoneMessageChecked: { type: Boolean, default: false },
              phrases: { type: Object },
              audioDetails: { type: Object, default: null },
            },
            question: {
              phrases: { type: Object, default: null },
              audioDetails: { type: Object, default: null },
            },
            answers: {
              correctAnsIndexes: { type: [Number], default: null },
              items: [
                {
                  phrases: { type: Object, default: null },
                  audioDetails: { type: Object, default: null },
                },
              ],
            },
          },

          mediaIndex: { type: String },
          activityPlaningColumnType: {
            type: Number,
            enum: ACTIVITY_PLANNING_COLUMN_TYPE,
          },
          generalSlidesConfigurations: {
            columnType: {
              type: Number,
              enum: {
                ...WEEKLY_SCHEDULE_ACTIVITY_CONFIG.columnType,
                ...WORD_FROM_THE_DEFINITION_CONFIG.columnType,
              },
            },
          },
        },
      ],
    },
    tempAudioGenerated: { type: Boolean },
    activityVisibility: {
      visibilityType: {
        type: Number,
        default: 0,
        enum: Object.values(ACTIVITY_VISIBILITY),
      },
      visibilityToOrg: {
        type: [Schema.Types.ObjectId],
        default: [],
        ref: 'Organization',
      },
    },
  },
  { timestamps: { createdAt: 'created_at', updatedAt: 'updated_at' } }
);

export const Activity = mongoose.model<MongooseActivity>(
  'Activity',
  ActivitySchema
) as any;

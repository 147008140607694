// @ts-nocheck
import mongoose, { Document, Schema } from 'mongoose';
import { PHONETICS_WORDS } from './phonetics-words';

export interface PhoneticsSentences {
  text: string;
}

export interface MongoosePhoneticsSentences
  extends PhoneticsSentences,
    Document {}

export const PHONETICS_SENTENCES = {
  errorType: {
    limitError: 'Not allowed to add more breakpoint.',
    image: 'Image is required.',
    sentence: 'Sentence is required.',
    sentenceReset: 'Click on Apply to reset words based on new sentence',
    validationError: 'Can not save the record. Please see the errors below.',
    serverError: 'Can not save the record. Please try again later.',
    updatedTimestampError:
      'Record was updated by another user. Please refresh the page and try again.',
    audio: 'Audio is required.',
    syllable: 'Syllables are required.',
    structure: 'Structure is required.',
    ipaSound: 'One or more sounds are not assigned.',
    punctuation: 'Punctuation is required.',
  },
};

const PhoneticsSentenceSchema = new Schema(
  {
    visibility: {
      visibilityType: {
        type: Number,
        default: 0,
        enum: Object.values(PHONETICS_WORDS.visiblity),
      },
      visibilityToOrg: {
        type: [Schema.Types.ObjectId],
        default: [],
        ref: 'Organization',
      },
    },

    published: { type: Boolean, default: false, index: true },
    text: { type: String, default: null },
    imagePath: { type: String, default: null },
    isolateImagePath: { type: String, default: null },
    illustrationImagePath: { type: String, default: null },
    innerStatus: {
      type: String,
      default: PHONETICS_WORDS.innerStatus[0],
      enum: PHONETICS_WORDS.innerStatus,
    },
    comment: {
      type: String,
      default: null,
    },
    tags: { type: [String], default: [] },
    language: { type: Schema.Types.ObjectId, ref: 'Language', index: true },
    targetWebsite: {
      type: [
        {
          website: { type: Schema.Types.ObjectId, ref: 'Website' },
          lastPublishedDate: { type: Date, default: null },
          prevalence: {
            type: String,
            default: PHONETICS_WORDS.prevalenceOptions[1],
            enum: PHONETICS_WORDS.prevalenceOptions,
          },
        },
      ],
      default: [],
    },
    audio: {
      type: {
        audioType: {
          type: String,
          default: null,
          enum: [...PHONETICS_WORDS.audioType, null],
        },
        path: { type: String, default: null },
        waitingForAudio: {
          type: Boolean,
          default: false,
        },
        audioSettings: { type: Object, default: {} },
      },
    },
    punctuation: { type: String, default: null },
    ownedBy: { type: Schema.Types.ObjectId, ref: 'Organization' },
    createdBy: { type: Schema.Types.ObjectId, ref: 'User' },
    updatedBy: { type: Schema.Types.ObjectId, ref: 'User' },
    randomSort: { type: Number, default: null },
    isAiGenerated: { type: Boolean, default: false },

    words: {
      type: [
        {
          text: {
            type: String,
            default: null,
          },
          syllableCount: { type: Number, index: true },
          syllable: {
            type: {
              syllableBreakdownIndexes: [{ type: Number }],
              syllableBreakdownStrength: [{ type: String }],
              blendsCombination: {
                type: [
                  [{ type: Schema.Types.ObjectId, ref: 'Phonetics-Sounds' }],
                ],
                default: [],
              },
            },
            default: null,
          },
          structure: {
            type: Schema.Types.ObjectId,
            ref: 'Phonetics-Structure',
            default: null,
            index: true,
          },
          initialSound: {
            type: Schema.Types.ObjectId,
            ref: 'Phonetics-Sounds',
            default: null,
            index: true,
          },
          finalSound: {
            type: Schema.Types.ObjectId,
            ref: 'Phonetics-Sounds',
            default: null,
            index: true,
          },
          medialSound: {
            type: [{ type: Schema.Types.ObjectId, ref: 'Phonetics-Sounds' }],
            default: [],
            index: true,
          },
          phoemeCount: {
            type: Number,
            default: null,
          },
          // punctuation: { type: String, default: null },
          partOfSpeech: {
            type: String,
            default: null,
            enum: [...PHONETICS_WORDS.partOfSpeech, null],
          },
          inUse: {
            type: Boolean,
            default: false,
          },
        },
      ],
    },
  },
  { timestamps: { createdAt: 'created_at', updatedAt: 'updated_at' } }
);

export const PhoneticsSentences = mongoose.model<MongoosePhoneticsSentences>(
  'Phonetics-Sentences',
  PhoneticsSentenceSchema
) as any;
